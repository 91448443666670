import React from 'react'
import { useEffect , useState} from 'react'
import Div from '../Div'
import AuthorWidget from '../Widget/AuthorWidget'
import RecentPost from '../Widget/RecentPost'
import SideMenuWidget from '../Widget/SideMenuWidget'
import TagWidget from '../Widget/TagWidget'

export default function Sidebar({setpostData,category,tags,isdisable}) {


  return (
    <>
      <Div className="cs-sidebar_item">
        {/* <AuthorWidget 
          src='/images/avatar_1.png'
          name='Kuber Jontra' 
          description='At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum.'
        /> */}
      </Div>
      <Div className="cs-sidebar_item">
        <SideMenuWidget title='Categories' isdisable={isdisable} setpostData= {setpostData} categoryData={category}/>
      </Div>
      {/* <Div className="cs-sidebar_item">
        <RecentPost title='Archives' data={recentPostData}/>
      </Div> */}
      <Div className="cs-sidebar_item">
        {/* <SideMenuWidget title='Archives' data={archiveData}/> */}
      </Div>
      <Div className="cs-sidebar_item">
        <TagWidget title='Tags' isdisable={isdisable} tags={tags} setpostData= {setpostData}/>
      </Div>
    </>
  )
}
