import { Icon } from '@iconify/react';
import React from 'react';
import { useEffect, useState } from "react";

import Slider from 'react-slick';
import Div from '../Div';
import Team from '.';
import { db } from "../../firebaseconfig/firebase"; 
import { collection, getDocs } from "firebase/firestore";
// import { servicePage , db} from "../../firebaseconfig/firebase";
// import { getFirestore , doc,getDoc} from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";






export default function TeamCard(){
  // const { state } = useLocation();

  // const { state } = useLocation();
  // const [currentId,  setCurrentId] = useState('');
  const [teamData, setteamData] = useState([]);
  // const [currentData, setcurrentData] = useState({});
  const fetchData = async () => {
       
    await getDocs(collection(db, "teamDetails"))
        .then((querySnapshot)=>{               
            const data = querySnapshot.docs
                .map((doc) => ({...doc.data(),id:doc.id }));
                // .map((doc) => ({...doc.data()}));
                // console.log(teamData ,data);
                setteamData(data); 
        })
   
}
useEffect(()=>{
  fetchData();
  
}, [])






   return (
    <div style={{display:'flex', columnGap:'30px', justifyContent:'center'}}>
      {teamData?.map((item, index) => (
        <Div key={index} >
          <Team 
            memberImage={item?.image}
            memberName={item?.name}
            memberDesignation={item?.designation}
           
          />
        </Div>
      ))}
    </div>
  );
}
