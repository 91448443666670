// src/CareerPage.jsx
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import "../Career/CareerPage.scss";
import PageHeading from "../PageHeading";
import Spacing from "../Spacing";
import Div from "../Div";
import Cta from "../Cta";

const CareerPage = () => {
  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    College: "",
    mobile: "",
    yop: "",
    cv: "", // Store base64 string instead of the file
  });

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    if (name === "cv") {
      const file = event.target.files[0];
      convertToBase64(file).then((base64) => {
        setUserData({ ...userData, [name]: base64 });
      });
    } else {
      value = event.target.value;
      setUserData({ ...userData, [name]: value });
    }
  };

  // Function to convert the file to a base64 string
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitData = async (event) => {
    event.preventDefault();
    const { fullName, email, College, mobile, yop, cv } = userData;

    if (fullName && email && mobile && College && yop && cv) {
      const res = await fetch(
        "https://nextin-technology-default-rtdb.firebaseio.com/userCareer.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullName,
            email,
            College,
            mobile,
            yop,
            cv, // Send the base64 string
          }),
        }
      );

      if (res.ok) {
        setUserData({
          fullName: "",
          email: "",
          College: "",
          mobile: "",
          yop: "",
          cv: "",
        });
        alert("Submitted successfully");
        document.querySelector("form").reset();
      } else {
        alert("Error submitting the data");
      }
    } else {
      alert("Please fill all the details");
    }
  };

  return (
    <>
      <PageHeading
        title="Join Our Team"
        bgSrc="https://static.nextintech.in/blog/blog_hero_bg.jpeg"
        pageLinkText="Carrer"
      />
      <Spacing lg="60" md="40" />
      <Div className="container">
        <form action="/submit-form" method="post" className="row">
          <Div className="col-sm-6">
            <label className="cs-primary_color">Full Name*</label>
            <input
              type="text"
              name="fullName"
              required
              className="cs-form_field"
              value={userData.fullName}
              onChange={postUserData}
            />
            <Spacing lg="20" md="20" />
          </Div>
          <Div className="col-sm-6">
            <label className="cs-primary_color">Email*</label>
            <input
              type="email"
              name="email"
              required
              className="cs-form_field"
              value={userData.email}
              onChange={postUserData}
            />
            <Spacing lg="20" md="20" />
          </Div>
          <Div className="col-sm-6">
            <label className="cs-primary_color">College*</label>
            <input
              type="text"
              name="College"
              required
              className="cs-form_field"
              value={userData.College}
              onChange={postUserData}
            />
            <Spacing lg="20" md="20" />
          </Div>
          <Div className="col-sm-6">
            <label className="cs-primary_color">Mobile*</label>
            <input
              type="text"
              name="mobile"
              required
              className="cs-form_field"
              value={userData.mobile}
              onChange={postUserData}
            />
            <Spacing lg="20" md="20" />
          </Div>

          <Div className="col-sm-6">
            <label className="cs-primary_color">Y.O.P*</label>
            <select
              name="yop"
              required
              className="cs-form_field"
              value={userData.yop}
              onChange={postUserData}
            >
              <option value="" style={{ color: "white", backgroundColor: "#181818" }}>
                Select Year
              </option>
              <option value="2025" style={{ color: "white", backgroundColor: "#181818" }}>
                2025
              </option>
              <option value="2026" style={{ color: "white", backgroundColor: "#181818" }}>
                2026
              </option>
              <option value="2027" style={{ color: "white", backgroundColor: "#181818" }}>
                2027
              </option>
              <option value="2028" style={{ color: "white", backgroundColor: "#181818" }}>
                2028
              </option>
            </select>
            <Spacing lg="20" md="20" />
          </Div>

          {/* Add CV upload option */}
          <Div className="col-sm-6">
            <label className="cs-primary_color">Upload CV*</label>
            <input
              type="file"
              name="cv"
              required
              className="cs-form_field"
              onChange={postUserData}
            />
            <Spacing lg="20" md="20" />
          </Div>

          <Div className="col-sm-12">
            <button
              type="submit"
              className="cs-btn cs-style1"
              onClick={submitData}
            >
              <span>Send</span>
              <Icon icon="bi:arrow-right" />
            </button>
          </Div>
        </form>
        <Spacing lg="145" md="80" />
        <Cta
          title="info@nextintech.in"
          bgSrc="https://static.nextintech.in/general/cta_bg_2.jpeg"
          variant="rounded-0"
        />
      </Div>
    </>
  );
};

export default CareerPage;
