import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Portfolio from "../Portfolio";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import { db } from "../../firebaseconfig/firebase";
import { collection, getDocs } from "firebase/firestore";

export default function PortfolioPage() {
  // pageTitle("Portfolio");
  const [active, setActive] = useState("all");
  const [itemShow, setItemShow] = useState(7);

  const [portfolioData, setportfolioData] = useState([]);
  const [categoryMenu, setcategoryMenu] = useState([]);

  const fetchData = async () => {
    await getDocs(collection(db, "portfolioData")).then((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setportfolioData(data[0]?.portfolios);

      const posts = data[0]?.portfolios || []; // Get the posts array from the data
      

      // Extract categories from posts
      const categories = posts.map((post) => post.category);
    

      // Remove duplicates by converting to Set and then back to array
      const uniqueCategories = [...new Set(categories)];

      // Set state with unique categories
      setcategoryMenu(uniqueCategories);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  // console.log(categoryMenu)


  useEffect(() => {
    window.scrollTo(0, 0);
    var title = "Portfolio | Nextin Technologies"
    var desc = "Dive into our showcase of past projects and success stories. See how NextinTech has helped businesses like yours achieve their goals with our innovative and effective solutions."
    const titleTag = document.querySelector('title');
    titleTag.innerText = title;
    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute('content', title)
    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute('content', desc)
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute('content', title)
    const metaDescriptionOG = document.querySelector("meta[property='og:description']");
    metaDescriptionOG.setAttribute('content', desc)
    const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
    metaTitleTwitter.setAttribute('content', title)
    const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
    metaDescriptionTwitter.setAttribute('content', desc)
    const canonical = document.querySelector("link[rel='canonical']");
    canonical.setAttribute('href', window.location.href)
    const metaUrlOG = document.querySelector("meta[property='og:url']");
    metaUrlOG.setAttribute('content', window.location.href)
    const metaUrlTwitter = document.querySelector("meta[property='twitter:url']");
    metaUrlTwitter.setAttribute('content', window.location.href)
  }, []);

  return (
    <>
      <PageHeading
        title="Portfolio"
        bgSrc="https://static.nextintech.in/portfolio/portfolio_hero_bg.jpeg"
        pageLinkText="Portfolio"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === "all" ? "active" : ""}>
                <span onClick={() => setActive("all")}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item ? "active" : ""}
                  key={index}
                >
                  <span onClick={() => setActive(item)}>
                    {/* {item.title} */}
                    {item}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? "col-lg-8" : "col-lg-4"
              } ${
                active === "all"
                  ? ""
                  : !(active === item.category)
                  ? "d-none"
                  : ""
              }`}
              key={index}
            >
              <Portfolio
                title={item?.title}
                subtitle="See Details"
                href={item?.href}
                src={item?.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Cta
        title="info@nextintech.in"
        bgSrc="https://static.nextintech.in/general/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  );
}
