import React, { useEffect, useState } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Pagination from "../Pagination";
import PostStyle2 from "../Post/PostStyle2";
import Div from "../Div";
import Sidebar from "../Sidebar.jsx";
import Spacing from "../Spacing";
import SearchWidget from '../Widget/SearchWidget'
import { db } from "../../firebaseconfig/firebase";
import { collection, getDocs } from "firebase/firestore";

export default function BlogPage() {
  // pageTitle("Blog");

  const [postData, setpostData] = useState([]);
  // const [category, setcategory] = useState([]);
  // const [tags, setTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3; // Number of blog posts to display per page
  const[sortedData,setSortedData]=useState();


  const fetchData = async () => {
    await getDocs(collection(db, "blogPage")).then((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

       // Extract all blog posts from data
       const allPosts = data.flatMap((blog) => blog.posts);

       // Calculate total number of pages
       const totalPages = Math.ceil(allPosts.length / postsPerPage)


      // Ensure that each blog has a "date" property
      data.forEach(blog => {
        blog.posts.forEach(post => {
            if (!post.date) {
                // Set a default date if "date" property doesn't exist
                post.date = new Date().toISOString();
            }
        });
    });

   
    // Set the state with the sorted posts
    setpostData(data[0]?.posts);

    });
  };
  useEffect(() => {
     // Sort the posts by date in descending order
     const sortedPosts = postData.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA;

  });
  setSortedData(sortedPosts);
  }, [postData]);


  useEffect(() => {
    fetchData();
  }, []);

// Function to handle page change
const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
  window.scrollTo(0, 0); // Scroll window to top on page change
};

  


// Calculate index of the first and last blog post to display on the current page
const indexOfLastPost = currentPage * postsPerPage;
const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentPosts = sortedData?.slice(indexOfFirstPost, indexOfLastPost);


useEffect(() => {
  window.scrollTo(0, 0);
  var title = "Blog | Nextin Technologies"
  var desc = "Stay up-to-date with the latest trends, insights, and industry news in the digital realm with NextinTech's blog. Our team of experts shares valuable knowledge, tips, and best practices to help you navigate the ever-evolving landscape of technology and digital marketing."
  const titleTag = document.querySelector('title');
  titleTag.innerText = title;
  const metaTitle = document.querySelector("meta[name='title']");
  metaTitle.setAttribute('content', title)
  const metaDescription = document.querySelector("meta[name='description']");
  metaDescription.setAttribute('content', desc)
  const metaTitleOG = document.querySelector("meta[property='og:title']");
  metaTitleOG.setAttribute('content', title)
  const metaDescriptionOG = document.querySelector("meta[property='og:description']");
  metaDescriptionOG.setAttribute('content', desc)
  const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
  metaTitleTwitter.setAttribute('content', title)
  const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
  metaDescriptionTwitter.setAttribute('content', desc)
    const canonical = document.querySelector("link[rel='canonical']");
    canonical.setAttribute('href', window.location.href)
    const metaUrlOG = document.querySelector("meta[property='og:url']");
    metaUrlOG.setAttribute('content', window.location.href)
    const metaUrlTwitter = document.querySelector("meta[property='twitter:url']");
    metaUrlTwitter.setAttribute('content', window.location.href)
}, []);

  return (
    <>
      <PageHeading
        title="Our Blog"
        bgSrc="https://static.nextintech.in/blog/blog_hero_bg.jpeg"
        pageLinkText="Blog"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-8">
            {
            (currentPosts?.map((post, index) => (
              <Div key={index}>
                <PostStyle2
                  thumb={post?.imgA}
                  // {`data:image/jpeg;base64, ${blogDetail?.imgA}`}
                  title={post?.title}
                  subtitle={post?.description.slice(0, 350)+'...'}
                  // subtitle={item?.subtitle.slice(0, 350)} // Slice the subtitle to first 100 characters
                  date={post?.date}
                  category={post?.category}
                  // categoryHref={item?.categoryHref}
                  href={post?.href}
                />
                {postData.length > index + 1 && <Spacing lg="95" md="60" />}
              </Div>
            )))}
            <Spacing lg="60" md="40" />
            <Pagination 
            currentPage={currentPage}
            totalPages={Math.ceil(postData.length / postsPerPage)}
            onPageChange={handlePageChange}
            />
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            <Spacing lg="0" md="80" />
            <Div className="cs-sidebar_item">
        <SearchWidget title='Search' data={postData} setpostData= {setpostData}/>
      </Div>
            <Sidebar
              setpostData={setpostData}
              // category={category}
              // tags={tags}
            />
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Apply For A Meeting"
          btnLink="/contact"
          bgSrc="https://static.nextintech.in/general/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
