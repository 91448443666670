import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import Div from '../Div'
import { db } from "../../firebaseconfig/firebase"; 
import { collection, getDocs } from "firebase/firestore";

export default function TagWidget({title, setpostData,isdisable}) {

  const [data, setData] = useState([]);
  const [tags, setTags] = useState([]);


  const fetchData = async () => {
       
    await getDocs(collection(db, "blogPage"))
        .then((querySnapshot)=>{               
            const data = querySnapshot.docs
                .map((doc) => ({...doc.data(),id:doc.id }));
                // console.log(data[0].postData);
                setData(data[0]?.posts); 
                // console.log(data[0]?.posts.map(post => post.category));                  
                                
                              // Initialize an array to store all tags
      let allTags = [];

      // Iterate through each document (blog) in the "blogPage" collection
      data.forEach((blog) => {
        const posts = blog.posts || []; // Get posts array from the blog
        const tagsArray = posts.flatMap((post) =>
          post.tags.split(",").map((tag) => tag.trim())
        ); // Extract and split tags from each post

        // Limit the number of tags to 20
    const limitedTags = tagsArray.slice(0, 20);

        allTags = [...allTags, ...limitedTags]; // Add the extracted tags to allTags array
      });

      // Remove duplicates by converting to Set and then back to array
      const uniqueTags = [...new Set(allTags)];

      // Now uniqueTags contains all individual tags from all posts in the "blogPage" collection
      // console.log(uniqueTags);

      // Set state with unique tags
      setTags(uniqueTags);
        })
   
}
useEffect(()=>{
  fetchData();
  
}, [])
  const searchTag=(e,tag)=>{
    e.preventDefault();
   
    const filteredPosts = []; // Array to store filtered posts

    
    for(let i=0 ; i< data.length;i++){
      // console.log('hii',data[i]);

      if (data[i].tags.toLowerCase().includes(tag.toLowerCase())){
        filteredPosts.push(data[i]); // Add matching post to the array
        // setpostData([data[i]])
        // console.log('hjk',[data[i]])
      }

    }
    // setpostData(filteredPosts); // Set the array of filtered posts as the new state
    // Sort the filtered posts by date in descending order
    const sortedPosts = filteredPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

    setpostData(sortedPosts); // Set the array of filtered and sorted posts as the new state
  }


  
  return (
    <>
      <h4 style={{color: isdisable ? "#242424" : "inherit" }} className="cs-sidebar_widget_title">{title}</h4>
      <Div className="tagcloud">
        {tags?.map((tag, index)=> (
         <button 
         key={index} 
          disabled={isdisable}
           className="tag-cloud-link"  
            onClick={(e) => searchTag(e,tag)} 
            style={{background:"none",color: isdisable ? "#242424" : "inherit" , border: isdisable? "2px solid #242424" : " " }}>

           {!isdisable?<Link  disabled={isdisable}  key={index}>{tag}</Link>:tag }
          </button>
        ))}
      </Div>
    </>
  )
}
