import React, { useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { pageTitle } from '../../helper'
import Card from '../Card'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
// import PricingTableList from '../PricingTable/PricingTableList'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import {servicePage } from '../../firebaseconfig/firebase';



export default function ServicesPage() {
  // pageTitle('Service');
  const navigate = useNavigate();
  useEffect(() => {
    const servicelist=servicePage()
    // console.log(servicelist)
    window.scrollTo(0, 0)
    var title = "Service | Nextin Technologies"
    var desc = "Explore our comprehensive range of services, from technical solutions like Mobile/Web Development to non-technical offerings such as SEO, Branding, and Photography. Whatever your digital needs, we have you covered."
    const titleTag = document.querySelector('title');
    titleTag.innerText = title;
    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute('content', title)
    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute('content', desc)
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute('content', title)
    const metaDescriptionOG = document.querySelector("meta[property='og:description']");
    metaDescriptionOG.setAttribute('content', desc)
    const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
    metaTitleTwitter.setAttribute('content', title)
    const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
    metaDescriptionTwitter.setAttribute('content', desc)
    const canonical = document.querySelector("link[rel='canonical']");
    canonical.setAttribute('href', window.location.href)
    const metaUrlOG = document.querySelector("meta[property='og:url']");
    metaUrlOG.setAttribute('content', window.location.href)
    const metaUrlTwitter = document.querySelector("meta[property='twitter:url']");
    metaUrlTwitter.setAttribute('content', window.location.href)
  }, [])



  return (
    <>
      <PageHeading 
        title='Services'
        bgSrc='https://static.nextintech.in/services/service_hero_bg.jpeg'
        pageLinkText='Services'
      />
      <Spacing lg='50' md='80'/>
      <Div className='cs-shape_wrap_4'>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4" style= {{marginTop:'12rem'}}>
              <SectionHeading
                title='Services we can help you with' 
                subtitle='What Can We Do'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className='col-xl-8'>
              <Div className='row'>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'>
                <Card
                    title='Desktop Application'
                    link="KUU45TwjKAXRM0ilW5zY"
                    src='https://static.nextintech.in/services/service_1.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6' style={{marginTop: "12rem"}}>
                  <Card

                    title='eCommerce systems'
                    link='pEPiNmSdP5BNDSsZ3YJw'
                    src='https://static.nextintech.in/services/service_2.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'>
                <Card
                    title='CRM & CMS'
                    link='GQUrVspdjQormVjD6R6G'
                    src='https://static.nextintech.in/services/service_3.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6' style={{marginTop: "12rem"}}>
                  <Card
                    title='Web Development'
                    link='Jn4DUZne8KmVvULWoKCF'
                    src='https://static.nextintech.in/services/service_4.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Bots & Web Scraping'
                    link='9Q1Gd3zBHpRMMO1DHHNS'
                    src='https://static.nextintech.in/services/service_5.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Chrome Extension'
                    link='HlHkvjuKFFt2HnbcEPm1'
                    src='https://static.nextintech.in/services/service_6.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile' style={{marginTop: "12rem"}}>
                <Card  
                    title='Mobile application'
                    link='HHsWXeA16hB3r594jKIg'
                    src='https://static.nextintech.in/services/service_7.jpg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Enterprise software Development'
                    link='fXhdnvN0TBrljdeBWopY'
                    src='https://static.nextintech.in/services/service_8.jpg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile' style={{marginTop: "12rem"}}>
                <Card  
                    title='Word press & Wix development'
                    link='L7xdjcwzxm6IrP1hQd6a'
                    src='https://static.nextintech.in/services/service_9.jpg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Machine learning  & AI applications'
                    link='FKBIBBimcMUya5Zevd4I'
                    src='https://static.nextintech.in/services/service_10.jpg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* <Div className="container">
        <SectionHeading
          title='Providing best <br/> pricing for client' 
          subtitle='Pricing & Packaging'
        />
        <Spacing lg='85' md='40'/>
        {/* <PricingTableList/> */}
      {/* </Div> */}
      {/* <Spacing lg='125' md='55'/>  */}
      {/* <TestimonialSlider/> */}
      {/* <Spacing lg='150' md='80'/> */}
      <Div className="container">
        <Cta 
          title='Let’s discuss make <br />something <i>cool</i> together' 
          btnText='Apply For A Meeting' 
          btnLink='/contact' 
          bgSrc='https://static.nextintech.in/general/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
