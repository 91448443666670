import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Div from "../Div";
import PageHeading from "../PageHeading";
import Spacing from "../Spacing";
import SectionHeading from "../SectionHeading";
import Cta from "../Cta";

export default function PrivacyPolicyPage() {
  pageTitle("PrivacyPolicy");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="Privacy Policy"
        bgSrc="https://static.nextintech.in/general/about_hero_bg.jpeg"
        pageLinkText="Privacy Policy"
      />
      {/* End Page Heading Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div>
          <SectionHeading title="" subtitle="">
            <h3 className="cs-section_subtitle">
              At Nextin Technologies, we are committed to safeguarding your
              privacy and ensuring the security of your personal information.
              This Privacy Policy outlines how we collect, use, disclose, and
              protect the data you provide while interacting with our website
              and services.
            </h3>
            <Div className="cs-separator cs-accent_bg"></Div>
            <Spacing lg="25" md="40" />
            <h3 className="cs-section_subtitle">
              <span className="orange">Information We Collect:</span>
            </h3>
            <p>
              <ul>
                1. Personal information:
                <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
                  <li>Name, email address, and contact details. </li>
                  <li>
                    Resume or portfolio details submitted for job applications.
                  </li>
                </ul>
                <br />
                2. Usage Data:
                <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
                  <li>
                    Information about how you navigate and interact with our
                    website.
                  </li>
                </ul>
                <br />
                3. Cookies and Analytics:
                <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
                  <li>
                    We may use cookies and Analytics tools to enhance your
                    browsing experience and collect data on website usage.
                  </li>
                </ul>
              </ul>
            </p>
            <Spacing lg="25" md="25" />
            <h3 className="cs-section_subtitle">
              <span className="orange">How We Use Your Information:</span>
            </h3>
            <p>
              <ul>
                1. Communication:
                <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
                  <li>
                    Responding to inquiries and providing information about our
                    services.
                  </li>
                </ul>
                <br />
                2. Job Applications:
                <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
                  <li>
                    Reviewing and processing job applications submitted through
                    our website.
                  </li>
                </ul>
                <br />
                3. Analytics:
                <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
                  <li>
                    Analyzing website usage to improve content and user
                    experience
                  </li>
                </ul>
              </ul>
            </p>
            <Spacing lg="25" md="25" />
            <h3 className="cs-section_subtitle">
              <span className="orange">Data Security:</span>
            </h3>
            <p>
              <ul>
                1. Confidentiality:
                <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
                  <li>
                    Your personal information is treated with strict
                    confidentiality.
                  </li>
                </ul>
                <br />
                2. Security Measures:
                <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
                  <li>
                    We employ industry-standard security measures to protect
                    against unauthorized access or disclosure.
                  </li>
                </ul>
              </ul>
            </p>
            <Spacing lg="25" md="25" />
            <h3 className="cs-section_subtitle">
              <span className="orange">Third-Party Disclosure:</span>
            </h3>
            <p>
              <ul>
                1. Service Providers:
                <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
                  <li>
                    Engage trusted third-party services providers for specific
                    website functionalities.
                  </li>
                </ul>
                <br />
                2. Legal Requirements:
                <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
                  <li>
                    Comply with legal obligations and respond to lawful
                    requests.
                  </li>
                </ul>
              </ul>
            </p>
            <Spacing lg="25" md="25" />
            <h3 className="cs-section_subtitle">
              <span className="orange">Your Choices:</span>
            </h3>
            <p>
              <ul>
                1. Cookies:
                <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
                  <li>
                    You can manage your cookie preference through your browser
                    settings
                  </li>
                </ul>
                <br />
                2. Contact preferences:
                <ul style={{ marginLeft: "40px", listStyleType: "disc" }}>
                  <li>Choose to opt-out of non-essential communications.</li>
                </ul>
              </ul>
            </p>
            <Spacing lg="25" md="25" />
            <h3 className="cs-section_subtitle">
              <span className="orange">Updates to Privacy Policy:</span>
            </h3>
            <p>
              <ul>
                We may update this Privacy Policy to reflect changes in our
                practices. Please review this policy periodically for any
                updates.
              </ul>
            </p>
            <Div className="cs-separator cs-accent_bg"></Div>
            <Spacing lg="30" md="30" />
            <h3 className="cs-section_subtitle">
              If you have any questions or concerns regarding our privacy
              practices, please contact us.
              <br />
              By using our website, you acknowledge and agree to the terms
              outlined in this Privacy Policy.
              <br />
              <br />
              Thank you for trusting NexIn Technologies.
            </h3>
          </SectionHeading>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Cta
        title="info@nextintech.in"
        bgSrc="https://static.nextintech.in/general/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  );
}
