import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import { Await } from 'react-router-dom';

export default function ContactPage() {

  const [userData, setUserData]= useState({
      fullName:'',
      email:'',
      projectType:'',
      mobile:'',
      description:''

  });

  let name,value;
  const postUserData = (event) =>{
    name=event.target.name;
    value=event.target.value;

    setUserData({...userData, [name]:value});
  };

  // pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
    var title = "Contact Us | Nextin Technologies"
    var desc = "Ready to take your online presence to the next level? Get in touch with our team today to discuss your project requirements, request a quote, or simply learn more about how NextinTech can help your business thrive."
    const titleTag = document.querySelector('title');
    titleTag.innerText = title;
    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute('content', title)
    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute('content', desc)
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute('content', title)
    const metaDescriptionOG = document.querySelector("meta[property='og:description']");
    metaDescriptionOG.setAttribute('content', desc)
    const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
    metaTitleTwitter.setAttribute('content', title)
    const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
    metaDescriptionTwitter.setAttribute('content', desc)
    const canonical = document.querySelector("link[rel='canonical']");
    canonical.setAttribute('href', window.location.href)
    const metaUrlOG = document.querySelector("meta[property='og:url']");
    metaUrlOG.setAttribute('content', window.location.href)
    const metaUrlTwitter = document.querySelector("meta[property='twitter:url']");
    metaUrlTwitter.setAttribute('content', window.location.href)
  }, []);

  //connect with firebase
  const submitData = async(event) => {
    event.preventDefault();
    const{  fullName,email,projectType,mobile,description} = userData;

    if(fullName && email  && mobile){
    const res = await fetch('https://nextin-technology-default-rtdb.firebaseio.com/userDataRecords.json',
    {method:'post',
      headers:{
        "Content-Type":"application/json",
      },
      body: JSON.stringify({fullName,email,projectType,mobile,description}),
    }

    
    );
      if(res){
        setUserData({
          fullName:'',
          email:'',
          projectType:'',
          mobile:'',
          description:''        });
        // alert("submitted")
      }
      else{
        alert("fill the details")
      }
    }
    else{
      alert("fill the details")
    }

  } ;

  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="https://static.nextintech.in/general/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Get In Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="/submit-form" method="post" className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input
                 type="text" 
                 name='fullName'
                 required 
                 className="cs-form_field" 
                 value={userData.fullName}
                 onChange={postUserData}
                 />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input
                 type="email"
                 name='email'
                required 
                className="cs-form_field"
                value={userData.email}
                onChange={postUserData}
                 />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type</label>
                <input 
                type="text"
                name='projectType'
                 className="cs-form_field"
                 value={userData.projectType}
                 onChange={postUserData}
                 />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input 
                type="text" 
                name='mobile'
                required 
                className="cs-form_field"
                value={userData.mobile}
                 onChange={postUserData}
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Description</label>
                <textarea
                  name='description'
                  value={userData.description}
                 onChange={postUserData}
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button type='submit' className="cs-btn cs-style1"
                  onClick={submitData}
                >
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117925.33439927742!2d88.34735275!3d22.53542735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1707669280334!5m2!1sen!2sin"
          allowFullScreen
          title="Google Map"
        />
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}
