import { Icon } from '@iconify/react'
import React, { useEffect,useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import Sidebar from '../Sidebar.jsx'
import Spacing from '../Spacing'
import parse from 'html-react-parser';
import { db } from "../../firebaseconfig/firebase"; 
import { collection, getDocs } from "firebase/firestore";

export default function BlogDetailsPage() {
  const params = useParams()
  const page = params.blogDetailsId;  


  const [blogDetail, setblogDetail] = useState([]);

  const fetchData = async () => {
       
    await getDocs(collection(db, "blogPage"))
        .then((querySnapshot)=>{               
            const data = querySnapshot.docs
                .map((doc) => ({...doc.data(),id:doc.id }));
                // console.log(data[0].posts);
                // setblogDetail(data[0]?.posts); 
                for(const key in data[0]?.posts){
                    // console.log(data[0]?.posts[key].href)
                  if(data[0]?.posts[key].href === '/blog/'+page){
                    setblogDetail(data[0]?.posts[key]);
                  }
                }
                            
        })
   
}
useEffect(()=>{
  fetchData();
  
}, [])

// console.log('hi',blogDetail);
// console.log('hi',{params.blogDetailsId});


// {blogDetail?.category}



const [userComment, setUserComment]= useState({
  fullName:'',
  email:'',
  website:'',
  comment:'',

});

let name,value;
const postUserComment = (event) =>{
name=event.target.name;
value=event.target.value;

setUserComment({...userComment, [name]:value});
};

pageTitle('Blog Details');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

//connect with firebase
const submitData = async(event) => {
event.preventDefault();
const{  fullName,email,website,comment} = userComment;

if(fullName && email){
const res = await fetch('https://nextin-technology-default-rtdb.firebaseio.com/userBlogComments.json',
{method:'post',
  headers:{
    "Content-Type":"application/json",
  },
  body: JSON.stringify({
    title: blogDetail.title, // Include the title here
    fullName,email,website,comment}),
}


);
  if(res){
    setUserComment({
      fullName:'',
      email:'',
      website:'',
      comment:'',       });
    alert("submitted")
  }
  else{
    alert("fill the details")
  }
}
else{
  alert("fill the details")
}

} ;

  
  return (
    <>
    {/* Start Page Heading Section */}
      <PageHeading 
        title='Blog'
        bgSrc='https://static.nextintech.in/blog/blog_details_hero_bg.jpeg'
        pageLinkText={blogDetail?.title}
      />
      {/* End Page Heading Section */}

      {/* Start Blog Details */}
      <Spacing lg='150' md='80'/>
      {/* <Div className="container"> */}
      <Div className="container" style={{backgroundColor:"white", padding:"10px", borderRadius:"10px"}}>
        <Div className="row">
          <Div className="col-lg-8"  style={{color:"#242424"}}>

            {/* Start Details Post Content */}
            <Div className="cs-post cs-style2">
              <Div className="cs-post_thumb cs-radius_15">
                <img src={blogDetail?.imgA}

                 alt="Post" className="w-100 cs-radius_15" />
              </Div>
              <Div className="cs-post_info"  style={{lineHeight:"35px", color:"#242424", fontSize:"20px", fontFamily:'source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif'}} >
                <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                  <span className="cs-posted_by">{blogDetail?.date}</span>
                  <span  className="cs-post_avatar">{blogDetail?.category}</span>
                </Div>
                <h2  style={{color:"#242424"}} className="cs-post_title ">{blogDetail?.title}</h2>
                {/* <p>{blogDetail?.description}</p> */}
                {parse(String(blogDetail?.description))}
                <p></p>
                {/* <blockquote className="cs-primary_font">
                  But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, who expound the actual teachings of the great explorer of the truth, the master.
                  <small>Loren Mulari</small>
                </blockquote> */}
                {/* <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated.</p> */}
                <Div className="row">
                  <Div className="col-md-6">
                    <img src={blogDetail?.imgB} alt="Blog Details" className="cs-radius_15 w-100" />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                  <Div className="col-md-6">
                    <img src={blogDetail?.imgC} alt="Blog Details" className="cs-radius_15 w-100" />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                </Div>
              </Div>
            </Div>
            {/* End Details Post Content */}

            {/* Start Comment Section */}
            <Spacing lg='30' md='30'/>
            <h2  style={{color:"#242424"}} className="cs-font_50 cs-m0">Leave A Reply</h2>
            <Spacing lg='5' md='5'/>
            <p   className="cs-m0">Your email address will not be published. Required fields are marked *</p>
            <Spacing lg='40' md='30'/>
            <form className="row">
              <Div   className="col-lg-6">
                <label>Full Name*</label>
                <input 
                style={{color:"black"}}
                type="text" 
                className="cs-form_field" 
                name='fullName'
                required 
                value={userComment.fullName}
                onChange={postUserComment}
                />
                <Div className="cs-height_20 cs-height_lg_20" />
                <Div data-lastpass-icon-root="true" style={{position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important'}} /></Div>
              <Div className="col-lg-6">
                <label>Email*</label>
                <input
                style={{color:"black"}}
                 type="email"
                 name='email'
                required 
                className="cs-form_field"
                value={userComment.email}
                onChange={postUserComment}
                 />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Website</label>
                <input
                style={{color:"black"}}
                 type="url"
                 name='website'
                // required 
                className="cs-form_field"
                value={userComment.website}
                onChange={postUserComment}
                 />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Write Your Comment*</label>
                <textarea 
                style={{color:"black"}}
                cols={30} 
                rows={7} 
                className="cs-form_field"
                name='comment'
                value={userComment.comment}
               onChange={postUserComment}
                />
                <Div className="cs-height_25 cs-height_lg_25" />
              </Div>
              <Div className="col-lg-12">
                <button className="cs-btn cs-style1"
                type='submit'
                onClick={submitData}
                >
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />               
                </button>
              </Div>
            </form>
            {/* End Comment Section */}
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            {/* Start Sidebar */}
            <Spacing lg='0' md='80'/>
            <Sidebar 
            isdisable={true}
            data={blogDetail}
            setpostData={setblogDetail}
             />
            {/* End Sidebar */}
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* Start Blog Details */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta 
          title='Let’s discuss make <br />something <i>cool</i> together' 
          btnText='Apply For A Meeting' 
          btnLink='/contact' 
          bgSrc='https://static.nextintech.in/general/cta_bg.jpeg'
        />
      </Div>
      {/* End CTA Section */}
    </>
  )
}
