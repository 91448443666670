import { Icon } from '@iconify/react'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Pagination({ currentPage, totalPages, onPageChange }) {

  // Generate page numbers for pagination
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }


  return (
    <ul className="cs-pagination_box cs-center cs-white_color cs-mp0 cs-semi_bold">
       {/* Previous page button */}
       {/* <li>
        <Link
          className="cs-pagination_item cs-center"
         
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <Icon icon="akar-icons:chevron-left" />               
        </Link>
      </li> */}

      {/* Page numbers */}
      {pageNumbers.map((pageNumber) => (
        <li key={pageNumber}>
          <Link
            className={`cs-pagination_item cs-center ${currentPage === pageNumber ? 'active' : ''}`}
            to="#"
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </Link>
        </li>
      ))}
            {/* Next page button */}
            <li>
        {/* <Link
          className="cs-pagination_item cs-center"
          // to="#"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <Icon icon="akar-icons:chevron-right" />               
        </Link> */}
      </li>
    </ul>
  )
}

      {/* <li>
        <Link className="cs-pagination_item cs-center active" to="/blog">1</Link>
      </li>
      <li>
        <Link className="cs-pagination_item cs-center" to="/blog">2</Link>
      </li>
      <li>
        <Link className="cs-pagination_item cs-center" to="/blog">3</Link>
      </li>
      <li>
        <Link className="cs-pagination_item cs-center" to="/blog">4</Link>
      </li>
      <li>
        <Link to="#" className="cs-pagination_item cs-center">
          <Icon icon="akar-icons:chevron-right" />               
        </Link>
      </li> */}
//     </ul>
//   )
// }
