import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Accordion from "../Accordion";
import Button from "../Button";
import Cta from "../Cta";
import IconBox from "../IconBox";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import TestimonialSlider from "../Slider/TestimonialSlider";
import Spacing from "../Spacing";
// import ServiceDetailsData from "../../data/ServiceDetailsData";
import Projects from "../ChooseUsCrousel/Projects";
import { collection, getDocs } from "firebase/firestore";
import { servicePage , db} from "../../firebaseconfig/firebase";
// import { getFirestore , doc,getDoc} from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";


export default function ServiceDetailsPage() {
  const { state } = useLocation();
  const [currentId,  setCurrentId] = useState('');
  const [serviceData, setServiceData] = useState([]);
  const [currentData, setcurrentData] = useState({});
  // pageTitle("Service Details");
  const params = useParams();

  const fetchData = async () => {
       
    await getDocs(collection(db, "ServiceDetailsData"))
        .then((querySnapshot)=>{               
            const data = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
                              
                // console.log(data);
                setServiceData(data); 
        })
   
}
useEffect(()=>{
  fetchData();
  var title = `${currentData?.title ?? ""} | Service | Nextin Technologies`
  var desc = currentData?.subtitle ?? ""
  const titleTag = document.querySelector('title');
  titleTag.innerText = title;
  const metaTitle = document.querySelector("meta[name='title']");
  metaTitle.setAttribute('content', title)
  const metaDescription = document.querySelector("meta[name='description']");
  metaDescription.setAttribute('content', desc)
  const metaTitleOG = document.querySelector("meta[property='og:title']");
  metaTitleOG.setAttribute('content', title)
  const metaDescriptionOG = document.querySelector("meta[property='og:description']");
  metaDescriptionOG.setAttribute('content', desc)
  const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
  metaTitleTwitter.setAttribute('content', title)
  const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
  metaDescriptionTwitter.setAttribute('content', desc)
    const canonical = document.querySelector("link[rel='canonical']");
    canonical.setAttribute('href', window.location.href)
    const metaUrlOG = document.querySelector("meta[property='og:url']");
    metaUrlOG.setAttribute('content', window.location.href)
    const metaUrlTwitter = document.querySelector("meta[property='twitter:url']");
    metaUrlTwitter.setAttribute('content', window.location.href)
  
}, [])

  useEffect(() => {
    // const { data } = state;
    // console.log(data);
    // setCurrentId(data?.serviceId);
    // Fetch service details data from Firestore
    setCurrentId(params.serviceDetailsId);
    const servicelist = servicePage();
  }, [ state]);

  useEffect(()=>{
   
    if  (serviceData.length >0 && currentId != ''){ 
      for  (let i=0 ;i < serviceData.length; i++){
        if( serviceData[i].id === currentId ){
          setcurrentData(serviceData[i]);
          // document.title = `${serviceData[i].name}` + pageTitle();
        }
        
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [serviceData]);

 

  return (
    <>
      <PageHeading
        title="Service Details"   
        bgSrc="https://static.nextintech.in/services/service_hero_bg.jpeg"
        pageLinkText={currentData.subtitle}
      />
      <Spacing lg="145" md="80" />

      <Div className="container">
      {serviceData && (
    <SectionHeading 
      title={currentData?.title}
      subtitle=''
      variant='cs-style1 text-center'
    />
  )}
        {/* <SectionHeading
          title={ServiceDetailsData[1].pageTitle}
          subtitle={ServiceDetailsData[1].subtitle}
          variant="cs-style1 text-center"
        /> */}
        <Spacing lg="90" md="45" />
        <Div className="row">
          
              {currentData?.keypoints?.map((itm,idx) => {
                return (
              <Div key={idx} className="col-lg-4">
                <IconBox
                  icon={itm?.icon}
                  title={itm?.title}
                  subtitle={itm?.subtitle}
                />
                <Spacing lg="30" md="30" />
              </Div>
            );
          })}
        </Div>
       
      </Div>
      <Spacing lg="120" md="50" />
      
      {/* <TestimonialSlider /> */}
      <Projects />
      <Spacing lg="145" md="80" />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title="Some pre questions and answers"
                subtitle="FAQ’s"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Apply For A Meeting"
          btnLink="/contact"
          bgSrc="https://static.nextintech.in/general/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
