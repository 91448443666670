import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';


const Project = (props) => {
    const { img, disc, title } = props.item;
  return (
    <Container style={{width:"95%"}} className='project'>
        <img src={img} alt="project" />
        <div className="disc">
            <h1>{parse(String(title))}</h1>
            <p>{disc}
            {/* <a href="#">demo</a> */}
            </p>
        </div>
    </Container>
  )
}

export default Project;

const Container = styled.div`
    height: 20rem;
    width: max-content;
    // background-color: #4e5156;
    margin: 0 0.5rem;
    padding: 0.2rem;
    border-radius: 25px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 400ms ease-in-out;
        
    }
    .disc{
        flex-direction: column;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 30%;
    right: 0;
    left: 0;
    bottom: -30rem;
    padding: 0.5rem;
    transition: all 400ms ease-in-out;
        
        h1{
            margin-top: 15px;
            font-size: 1rem;
        }
    
        p{
            margin-top: 10px;
            width: 90%;
            font-size: 0.8rem;
            
        }
    }

    
    &:hover img {
        transform: scale(1.3);
    }

    &:hover .disc {
        background: #00000078;
        bottom: 0rem;
    }

 

`