import React, { useRef, useEffect, useState } from 'react'
import Slider from 'react-slick';
import Project from './Project';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from 'styled-components';
import { db } from "../../firebaseconfig/firebase";
import { collection, getDocs } from "firebase/firestore";



var settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows : false,
    responsive: [
      // {
      //   breakpoint: 1600,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 1,
      //     infinite: true,
      //     centerMode : false
      //   }
      // },
      {
        breakpoint: 865,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode : true
        }
      },
      {
        breakpoint: 730,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode : false
        }
      },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 1,
      //     initialSlide: 2,
      //     centerMode : false
      //   }
      // },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
          centerMode : false
        },
      }
    ]
  };
const SliderComp = () => {

  const [sliderData, setSliderData] = useState([]);

const fetchData = async () => {
  await getDocs(collection(db, "whyChooseUs")).then((querySnapshot) => {
    const data = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    // console.log(data[0]?.list);
    setSliderData(data[0]?.list);
  });
};
useEffect(() => {
  fetchData();
}, []);



  const arrowRef = useRef(null);
    let sliderProject = "";
    sliderProject = sliderData.map((item, i) => (
        <Project item = {item} key={i}/>
    ))
  return (
    <Container id='slider'  style={{overflow:'hidden'}}>
      <Slider ref={arrowRef} {...settings}  >
      {sliderProject}
      </Slider>
      {/* <Buttons>
        <button 
        onClick={() => arrowRef.current.slickPrev()}
        className='back'><IoIosArrowBack/></button>
        <button 
        onClick={() => arrowRef.current.slickNext()}
        className='next'><IoIosArrowForward/></button>
      </Buttons> */}
    </Container>
  )
}

export default SliderComp;

const Container = styled.div`
  position: relative;
`

const Buttons = styled.div`
  button{
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
    background-color: #ff4a171a;
    cursor: pointer;
    color: #ff3800;
    border: none;
    position: absolute;
    top: 45%;
    right: -1rem;
  }

  .back{
    left: -1rem;
  }
`