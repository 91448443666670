import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutPage from "./components/Pages/AboutPage";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import Home from "./components/Pages/Home";
import PortfolioDetailsPage from "./components/Pages/PortfolioDetailsPage";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
import ServicesPage from "./components/Pages/ServicesPage";
import TeamPage from "./components/Pages/TeamPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import TeamDetails from "./components/Pages/TeamDetails";
// import CaseStudyShowcaseHome from "./components/Pages/CaseStudyShowcaseHome";
import Layout from "./components/Layout";
// import CaseStudyDetailsPage from "./components/Pages/CaseStudyDetailsPage";
import BlogPage from "./components/Pages/BlogPage";
import BlogDetailsPage from "./components/Pages/BlogDetailsPage";
import PrivacyPolicyPage from "./components/Pages/PrivacyPolicyPage";
import CareerPage from "./components/Pages/CareerPage";

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/service" element={<ServicesPage />} />
        <Route
          path="/service/:serviceDetailsId"
          element={<ServiceDetailsPage />}
        />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route
          path="/portfolio/:portfolioDetailsId"
          element={<PortfolioDetailsPage />}
        />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:blogDetailsId" element={<BlogDetailsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/team/:teamDetails" element={<TeamDetails />} />
        {/* <Route
          path="/case-study/:caseStudyDetailsId"
          element={<CaseStudyDetailsPage />}
        /> */}
        <Route path="/PrivacyPolicyPage" element={<PrivacyPolicyPage />} />
        <Route path="/CareerPage" element={<CareerPage />} />
      </Route>
      {/* <Route element={<Layout headerVariant="cs-site_header_full_width" />}>
        <Route
          path="/case-study-showcase"
          element={<CaseStudyShowcaseHome />}
        />
      </Route> */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
