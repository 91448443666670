import React from 'react'
import { useEffect , useState} from 'react'
import Slider from "react-slick";
import Div from '../Div';
import Post from '../Post';
import { db } from "../../firebaseconfig/firebase"; 
import { collection, getDocs } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

export default function PostSlider() {



  const [blogSliderData, setblogSliderData] = useState([]);
  const navigate = useNavigate();
  

  const fetchData = async () => {
       
    await getDocs(collection(db, "blogPage"))
        .then((querySnapshot)=>{               
            const data = querySnapshot.docs
                .map((doc) => ({...doc.data(),id:doc.id }));
                // console.log(data[0].postData);
                setblogSliderData(data[0]?.posts); 
                            
        })
   
}
useEffect(()=>{
  fetchData();
  
}, [])

// console.log('hi',blogSliderData);





  
  /** Slider Settings **/
  const settings = {
    dots: false,
    arrows:false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings} className='cs-gap-24'>
      {blogSliderData.map((item,index)=>(
          <Div key={index}>
            <Post 
             url={item?.href}
              src={item?.imgB} 
              // alt={item.alt} 
              date={item?.date}
              title={item?.title}
            />
          </Div>
        ))}
    </Slider>
  )
}
