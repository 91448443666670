import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <a href='https://www.linkedin.com/company/nextin-technologies' className="cs-center">
        <Icon icon="fa6-brands:linkedin-in" />
      </a>
      <a href='https://twitter.com/NextInTek' className="cs-center">
        <Icon icon="fa6-brands:twitter" />               
      </a>
      {/* <Link to='/' className="cs-center">
        <Icon icon="fa6-brands:youtube" />              
      </Link> */}
      {/* <Link to='/' className="cs-center">
        <Icon icon="fa6-brands:slack" />
      </Link> */}
    </Div>
  )
}
