import { useState, useEffect } from 'react'
import React  from 'react'
import Div from '../Div'
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'
import { useNavigate } from "react-router-dom";
import PrivacyPolicyPage from  '../Pages/PrivacyPolicyPage';

export default function Footer({copyrightText, logoSrc, logoAlt, text}) {

  // const handleServiceClick = function() {
 
    
  //   window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls to the top of the page
  // };
  
  const copyrightLinks = [
    {
      title: 'Privacy Policy',
      href: '/PrivacyPolicyPage'
    }
  ]
  const navigate = useNavigate();
  
  const serviceMenu1 = [
    {
      title: 'Desktop Application',
      link:"KUU45TwjKAXRM0ilW5zY"
    },
    {
      title: 'Web Development',
            link:'Jn4DUZne8KmVvULWoKCF'
    },
    {
      title: 'Bots & Web Scraping',
      link:'9Q1Gd3zBHpRMMO1DHHNS'
    },
    {
      title: 'Chrome Extension',
      link:'HlHkvjuKFFt2HnbcEPm1'
    },
    {
      title: 'eCommerce systems',
      link:'pEPiNmSdP5BNDSsZ3YJw'
    },

  ]
  const serviceMenu = [

    {
      title: 'CRM & CMS',
      link:'GQUrVspdjQormVjD6R6G'
    },
    {
      title: 'Enterprise software development (ERP)',
      link:'fXhdnvN0TBrljdeBWopY'
    },
    {
      title: 'Mobile application',
      link:'HHsWXeA16hB3r594jKIg'
    },
    {
      title: 'Word press & Wix development',
      link:'L7xdjcwzxm6IrP1hQd6a'
    },
    {
      title: 'Machine learning and AI applications',
      link:'FKBIBBimcMUya5Zevd4I'
    },
  ]

  return (
    <footer className="cs-fooer">
      {/* <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget 
                  logoSrc='https://static.nextintech.in/footer_logo.svg' 
                  logoAlt='Logo'
                />
                <SocialWidget/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <h5>services</h5>
                <div>
                  {serviceMenu1.map((itm, idx)=>{
                    return(
                      <button onClick={()=>{
                        navigate("/service-details",{ state: { data: { serviceId: itm.link} } })
                        // handleServiceClick()
                        window.location.reload()
                      }}
                      key={idx}
                       style={{border:'none', background:'none', textAlign:'left', marginBottom:'1rem'}}>
                        {itm.title}
                      </button>
                    )
                  })}
                </div>
              </Div>
            </Div> */}
            {/* <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu}  menuHeading='Services'/>
                <h5>    </h5>
                <div>
                  {serviceMenu.map((itm, idx)=>{
                    return(
                      <button onClick={()=>{
                        navigate("/service-details", { state: { data: { serviceId: itm.link} } })
                        // handleServiceClick()
                        window.location.reload()
                      }}
                      key={idx}
                       style={{border:'none', background:'none', textAlign:'left', marginBottom:'1rem'}}>
                        {itm.title}
                      </button>
                    )
                  })}
                </div>
              </Div>
            </Div> */}
            {/* <Div className="col-lg-3 col-sm-6" style={{display:'flex', justifyContent:'center'}}>
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Contact Us'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
        <Spacing lg="85" md="45" />

      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2021-24.</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=' cs-style2'/>
          </Div>
        </Div>
      </Div>
    </footer>
  )
}
