import React from 'react'
import styled from 'styled-components';
import SliderComp from './Slider';
import { Zoom } from 'react-awesome-reveal';

const Projects = () => {
  return (
    <Container id='projects_container'>
        <Zoom  className='zoom'>
            <h2>"Discover the reasons to <span className="orange">choose us"</span></h2>
            <p>Unmatched Excellence in Every Aspect</p>
        </Zoom>
        <Slide className='slide'>
            <SliderComp/>
        </Slide>
    </Container>
  )
}

export default Projects;

const Container = styled.div`
    width: 80%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 3rem;
    text-align: center;
    position: relative;
    @media(max-width: 840px){
        width: 90%;
    }
    h2{
        font-size: 42px;
    }

    p{
        padding-bottom: 1.5rem;
        width: 28rem;
        margin: 0 auto;
        padding-top: 0.5rem;
        padding-bottom: 2.rem;
        font-size:20px;
        @media(max-width : 500px){
            width: 90%;
        }
    }
    
`

const Slide = styled.div``