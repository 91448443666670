import { Icon } from "@iconify/react";
import React, { useState, useEffect } from "react";
import { db } from "../../firebaseconfig/firebase";
import { collection, getDocs } from "firebase/firestore";

export default function SearchWidget({ title, alldata, setpostData }) {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [noResults, setNoResults] = useState(false); 

  const fetchData = async () => {
    await getDocs(collection(db, "blogPage")).then((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setData(data[0]?.posts);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
 
  


  const searchBlog = () => {
   
    const temp = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].title.toLowerCase().includes(search.toLowerCase())) {
        // setpostData([data[i]]);
        temp.push(data[i]);
      }
    }
     setpostData(temp);
     setNoResults(temp.length === 0 && search.length > 0);
  };

  useEffect(() => {
    searchBlog();
    if(search.length===0){setpostData(data);
      setNoResults(false);
    }
  }, [search]);
  return (
    <>
      <h4 className="cs-sidebar_widget_title">{title}</h4>
      
      <form className="cs-sidebar_search">
        <input
          type="text"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
  
       
          }}
          placeholder="Search..."
        />

        <button  className="cs-sidebar_search_btn">
          <Icon icon="material-symbols:search-rounded" />
        </button>
      </form>
      {noResults && <p>No results found</p>}
    </>
  );
}
