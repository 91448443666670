import { Icon } from '@iconify/react'
import React , { useState,useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { db } from "../../firebaseconfig/firebase"; 
import { collection, getDocs } from "firebase/firestore";

export default function SideMenuWidget({title,setpostData,currentPage,isdisable}) {
  
  // console.log('hifhdgi',categoryData);



  const [data, setData] = useState([]);
  const [category, setcategory] = useState([]);
 


  const fetchData = async () => {
    // const startIndex = (currentPage - 1) * 3; // Assuming 3 items per page
    // const endIndex = startIndex + 3; // Assuming 3 items per page
       
    await getDocs(collection(db, "blogPage"))
        .then((querySnapshot)=>{               
            const data = querySnapshot.docs
                .map((doc) => ({...doc.data(),id:doc.id }));
                // console.log(data[0].postData);
                setData(data[0]?.posts);
      const posts = data[0]?.posts || []; // Get the posts array from the data

      // Extract categories from posts
      const categories = posts.map((post) => post.category);

      // Remove duplicates by converting to Set and then back to array
      const uniqueCategories = [...new Set(categories)];

      // Set state with unique categories
      setcategory(uniqueCategories);
                            
        })
   
}
useEffect(()=>{
  fetchData();
  
}, [currentPage])
  const searchCategory=(e,item)=>{
   
    e.preventDefault();
   
    const filteredPosts = []; // Array to store filtered posts

    
    for(let i=0 ; i< data.length;i++){
      // console.log('hii',data[i]);

      if (data[i].category.toLowerCase().includes(item.toLowerCase())){
        filteredPosts.push(data[i]); // Add matching post to the array
        // setpostData([data[i]])
        // console.log([data[i]])
      }

    }
    // setpostData(filteredPosts); // Set the array of filtered posts as the new state
    // Sort the filtered posts by date in descending order
    const sortedPosts = filteredPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

    setpostData(sortedPosts); // Set the array of filtered and sorted posts as the new state
  }

  return (
    <>
      <h4 style={{color: isdisable ? "#242424" : "inherit" }} className="cs-sidebar_widget_title">{title}</h4>
      <ul style={{color: isdisable ? "#242424" : "inherit" }} className='cs-side_menu_widget'>
        {category?.map((item, index)=> (
          // <li key={index}>
          <li key={index} style={{cursor:"pointer"}}  > 
            <Icon icon="material-symbols:keyboard-double-arrow-right-rounded" />
            <button  disabled={isdisable} onClick={(e) => searchCategory(e,item)} style={{background:"none", border:"none"}}>
           {!isdisable?<Link disabled={isdisable} key={index}>{item}</Link>:item }
            </button>
            {/* {item} */}
          </li>
        ))}
      </ul>
      {/* <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} /> */}
    </>
  )
}
