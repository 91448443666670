import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';
import PortfolioSlider from '../Slider/PortfolioSlider';
import Projects from '../ChooseUsCrousel/Projects';
import styled from 'styled-components';
import TeamCard from '../Team/TeamCard';


export default function AboutPage() {
  // pageTitle('About');

  const funfaceData = [
    {
      title: 'Global Happy Clients',
      factNumber: '40K',
    },
    {
      title: 'Project Completed',
      factNumber: '50K',
    },
    {
      title: 'Team Members',
      factNumber: '245',
    },
    {
      title: 'Digital products',
      factNumber: '550',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    var title = "About | Nextin Technologies"
    var desc = "Learn about NextinTech's journey, our mission, and the values that drive us. Meet our dedicated team of experts committed to delivering top-quality services tailored to your needs."
    const titleTag = document.querySelector('title');
    titleTag.innerText = title;
    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute('content', title)
    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute('content', desc)
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute('content', title)
    const metaDescriptionOG = document.querySelector("meta[property='og:description']");
    metaDescriptionOG.setAttribute('content', desc)
    const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
    metaTitleTwitter.setAttribute('content', title)
    const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
    metaDescriptionTwitter.setAttribute('content', desc)
    const canonical = document.querySelector("link[rel='canonical']");
    canonical.setAttribute('href', window.location.href)
    const metaUrlOG = document.querySelector("meta[property='og:url']");
    metaUrlOG.setAttribute('content', window.location.href)
    const metaUrlTwitter = document.querySelector("meta[property='twitter:url']");
    metaUrlTwitter.setAttribute('content', window.location.href)
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="https://static.nextintech.in/general/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          {/* <Div className="col-xl-5 col-lg-7"style={{width:'510px'}}> */}
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Your Partner for Seamless Digital Solutions."
              subtitle="About Our Agency"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                As a leading solution provider, we offer comprehensive IT services, ensuring the reliability  and efficiency of your digital infrastructure. Our AI expertise propels business into the future, delivering the intelligent solutions that optimise processes, enhance decision-making and elevate overall performance. Let us empower your business with the latest in technology and strategic consultancy. We offer seamless client and customer-centric products, cross-platform and accurate, as per the needs and the aesthetics of your company. 
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="https://static.nextintech.in/general/about_img_1.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Spacing lg="25" md="25" />
          <Div className="col-lg-7" style={{width:'670px' ,marginTop:'35px',marginRight:'30px'}}>
            <img
              src="https://static.nextintech.in/general/about_img_2.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5" style={{width:'613px'}}>

          <SectionHeading
              title="We're ready to Serve you with best."
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
             At nextintech.in we aim to deliver you the service you need for establishing your company online which will eventually help you grow and expand your business globally and earn revenues in manifolds.
              With our broad range of services,we bring to you the most affordable freelance services in the entire industry. We have teams that dedicate to their specific field of domains whether it is technical services like mobile/web development, logo, illustration, ecommerce system, machine learning, desktop applications, chrome extension & Graphic designing or other non-technical but high-in-demand industry services like SEO, Branding, Photography, Marketing, Video designing, we have got you covered for everything.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            {/* <img
              src="/images/about_img_3.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" /> */}
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFact
          title="Our fun fact"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
          data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

        <Spacing lg="25" md="0" />
        <Container>
        {/* <Carousel/> */}
        <Projects/>
        {/* <PortfolioSlider /> */}
      
      {/* </Div> */}
    </Container>
      {/* End Why Choose Section */}
        {/* Start Team Section */}
        <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        {/* <TeamSlider /> */}
        <TeamCard/>
      </Div>
     

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Apply For A Meeting"
          btnLink="/contact"
          bgSrc="https://static.nextintech.in/general/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}


const Container = styled.div``;

